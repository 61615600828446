import { MouseEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Organization } from '@Types/organization/organization';
import { useTranslation, Trans } from 'react-i18next';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { getBundledPrice } from 'helpers/utils/bundleItemsHelpers';
import { useCart } from 'frontastic';
import DiscountForm from 'components/commercetools-ui/discount-form';
import { LoadingIcon } from 'components/commercetools-ui/icons/loading';
import ReassignCartButton from 'components/commercetools-ui/cart/reassignCartButton';
import { number } from 'yup';
import { useStores } from 'frontastic';

interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly submitButtonLabel?: string;
  readonly disableSubmitButton?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;
  currentStep?: string;
  isQuoteRequestDisabled?: boolean;
  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
  submitLoading?: boolean;
  organization?: Organization;
  minOrderAmount?: Number
}

const OrderSummary = ({
  cart,
  onSubmit,
  showSubmitButton = true,
  showDiscountsForm = true,
  submitButtonLabel,
  disableSubmitButton,
  termsLink,
  cancellationLink,
  privacyLink,
  currentStep,
  isQuoteRequestDisabled,
  submitLoading,
  organization,
}: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['checkout']);
  const router = useRouter();
  const { createQuoteRequestFromCurrentCart, getCart, updateCart } = useCart();

  const orderAmountMin = (organization?.businessUnit?.custom?.fields?.orderAmountMin ?? 0)  * 100;
  const [isRequestAQuoteDisabled, setIsRequestAQuoteDisabled] = useState(false);
  const [isQuoteRequestDisplayed, setIsQuoteRequestDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quoteComment, setQuoteComment] = useState('');

  const submitButtonClassName = `${disableSubmitButton ? 'opacity-75 pointer-events-none' : ''} ${
    !showDiscountsForm ? 'mt-7' : ''
  } w-full rounded-md border border-transparent py-3 px-4 text-base shadow-sm font-medium text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-gray-50 flex flex row items-center justify-center`;

  const [sum, setSum] = useState(cart?.sum.centAmount)
  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-accent-500 hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={cancellationLink}
    />,
    <ReferenceLink
      key={2}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={privacyLink}
    />,
  ];

  const totalTaxes = cart?.taxed?.taxPortions?.reduce(
    (a, b) => ({
      ...a,
      centAmount: a.centAmount + b.amount.centAmount,
    }),
    {
      centAmount: 0,
      fractionDigits: cart?.taxed?.taxPortions?.[0]?.amount.fractionDigits,
      currencyCode: cart?.taxed?.taxPortions?.[0]?.amount.currencyCode,
    },
  );

  const productPrice = cart?.lineItems?.reduce(
    (a, b: LineItem) => {
      if (b.discountedPrice) {
        return {
          ...a,
          centAmount: a.centAmount + getBundledPrice(b) + b.discountedPrice.centAmount * b.count,
        };
      } else {
        return {
          ...a,
          centAmount: a.centAmount + getBundledPrice(b) + b.price.centAmount * b.count,
        };
      }
    },
    {
      centAmount: 0,
      fractionDigits: cart?.lineItems?.[0]?.price?.fractionDigits,
      currencyCode: cart?.lineItems?.[0]?.price?.currencyCode,
    },
  );

  const discountPrice = cart?.lineItems?.reduce(
    (a, b) => {
      return {
        ...a,
        centAmount:
          a.centAmount -
          b.count *
            b.discounts.reduce((x, y) => {
              return x + y.discountedAmount.centAmount;
            }, 0),
      };
    },
    {
      centAmount: 0,
      fractionDigits: cart?.lineItems?.[0]?.price?.fractionDigits,
      currencyCode: cart?.lineItems?.[0]?.price?.currencyCode,
    },
  );

  let volumeArr = [];
  volumeArr = cart.lineItems.map((lineItem) =>
    lineItem.variant.attributes.outer_volume !== undefined ? (lineItem.variant.attributes.outer_volume * ((0 < lineItem.variant.attributes.number_of_sku_by_outer ?? 0)
  ? lineItem.count/lineItem.variant.attributes.number_of_sku_by_outer
  : 0)) : 0,
  );
  const volumetry = volumeArr.reduce((a, b) => a + b);

  let outerArr = [];
  outerArr = cart.lineItems.map((lineItem) =>
    (0 < lineItem.variant.attributes.number_of_sku_by_outer ?? 0)
      ? lineItem.count/lineItem.variant.attributes.number_of_sku_by_outer
      : 0
  );
  const outer = outerArr.reduce((a, b) => a + b);

  let palletsArr = [];
  palletsArr = cart.lineItems.map((lineItem) =>
    (0 < lineItem.variant.attributes.number_of_sku_by_pallet ?? 0)
      ? (lineItem.count/lineItem.variant.attributes.number_of_sku_by_pallet)
      : 0
  );

  let palletsArrTotals = cart.lineItems.map((lineItem) =>
    (0 < lineItem.variant.attributes.number_of_sku_by_pallet ?? 0)
      ? (lineItem.count/lineItem.variant.attributes.number_of_sku_by_pallet) * (true === (lineItem.variant.attributes.produit_dangereux ?? false) ? 2 : 1)
      : 0
  );

  const pallets = palletsArr.reduce((a, b) => a + b);
  const palletsTotals = palletsArrTotals.reduce((a, b) => a + b);
  let palettesIn20Ft = palletsTotals%42<=21 ? palletsTotals%42 : 0.0;
  let palettesIn40Ft = palletsTotals%42>21 ? palletsTotals%42 : 0.0;
  let count20FtContainer = (palettesIn20Ft/21);

  let count40FtContainer = palletsTotals/42;

  const handleQuoteRequest = () => {
    setIsQuoteRequestDisplayed(true);
    handleUpdateAddress();
  };

  const handleCreateQuote = async () => {
    setIsLoading(true);
    await createQuoteRequestFromCurrentCart(quoteComment);
    setIsLoading(false);
    getCart();
    router.push('/quote-thank-you');
  };

  const handleUpdateAddress = async () => {
    setIsLoading(true);
    await updateCart({
      shipping: cart.shippingAddress,
      billing: cart.billingAddress,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const canDisplyRequest = !(cart?.sum.centAmount < orderAmountMin);
    setIsRequestAQuoteDisabled(canDisplyRequest);
    if (false === canDisplyRequest) {
      setIsQuoteRequestDisplayed(!(cart?.sum.centAmount < orderAmountMin));
    }
    setSum(cart?.sum.centAmount);
  }, [cart]);

  return (
    <section aria-labelledby="summary-heading" className="mb-8 w-full">
      <div className="cart-summary-wrapper">
        <header>
          <h2 id="summary-heading" className="cart-summary-title border-color-gray border-b pb-5">
            {formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}
          </h2>
        </header>

        <dl className="space-y-4 py-5">
          
          <div className="flex items-center justify-between">
            <dt className='font-bold'>{formatCartMessage({ id: 'order.delivery_informations', defaultMessage: 'Delivery Informations' })}</dt>
          </div>
          {volumetry > 0 && (
            <div className="flex items-center justify-between">
              <dt className="">Volumetry</dt>
              <dd className="whitespace-nowrap">
                {(volumetry/1000).toFixed(1)} m<sup>3</sup>
              </dd>
            </div>
          )}
          <div className="flex items-center justify-between">
              <dt>Total <span className='font-bold font-color-bic'>outer</span> quantity</dt>
              <dd className="whitespace-nowrap">
                {outer.toFixed(1)}
              </dd>
          </div>
          <div className="flex items-center justify-between">
              <dt>Total <span className='font-bold font-color-bic'>pallets</span> quantity</dt>
              <dd className="whitespace-nowrap">
                {pallets.toFixed(1)}
              </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className='font-bold'>{formatCartMessage({ id: 'order.delivery_containerization', defaultMessage: 'Containerization' })}</dt>
          </div>
          <div className="flex items-center justify-between">
            <dt className='w-full'> 
              <svg className="h-6 w-6 font-color-bic mr-1"  fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{float: 'inline-start'}}>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <div>{formatCartMessage({ id: 'order.delivery_containerization_20_ft_info', defaultMessage: '20Ft. Cont = 21 Pallets' })}</div>
            </dt>
          </div>
          <div className="flex items-center justify-between">
            <dt className='w-full'> 
              <svg className="h-6 w-6 font-color-bic mr-1"  fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{float: 'inline-start'}}>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
              <div>{formatCartMessage({ id: 'order.delivery_containerization_40_ft_info', defaultMessage: '40Ft. Cont = 42 Pallets' })}</div>
            </dt>
          </div>
          <div className="flex items-center justify-between">
              <dt>{formatCartMessage({ id: 'order.delivery_containerization_total_20th', defaultMessage: 'Total 20ft container utilization' })}</dt>
              <dd className="whitespace-nowrap">
              {count20FtContainer===0.00 && count20FtContainer.toFixed(0) || count20FtContainer.toFixed(2)}
              </dd>
          </div>
          <div className="flex items-center justify-between">
              <dt>{formatCartMessage({ id: 'order.delivery_containerization_total_40th', defaultMessage: 'Total 40ft container utilization' })}</dt>
              <dd className="whitespace-nowrap">
                {count20FtContainer==0.00 && count40FtContainer.toFixed(2) || count40FtContainer.toFixed(0)}
              </dd>
          </div>
          <div className="text-gray-400">
          {formatCartMessage({ id: 'order.delivery_containerization_info', defaultMessage: 'Above containerization is an estimation only - Actual load per container might vary based on the mix of products ordered and the possibility to double stack the pallets. For Lighters specifically, please consider 10 pallets per 20Ft. Cont. and 20 pallets per 40Ft. Cont.' })}
          </div>
          {/*<div className="flex items-center justify-between">
              <dt>20ft container palettes : {(pallets % 21).toFixed(1)}/21</dt>
              <dd className="whitespace-nowrap">
                {(((pallets % 21)/21)*100).toFixed(2)} %
              </dd>
          </div>
          <div className="flex items-center justify-between">
              <dt>20ft container filled</dt>
              <dd className="whitespace-nowrap">
                {Math.floor(pallets / 21)}
              </dd>
          </div>

          <div className="flex items-center justify-between">
              <dt>40ft container palettes : {(pallets % 42).toFixed(1)}/42</dt>
              <dd className="whitespace-nowrap">
                {(((pallets % 42)/42)*100).toFixed(2)} %
              </dd>
          </div>
          <div className="flex items-center justify-between">
              <dt>40ft container filled</dt>
              <dd className="whitespace-nowrap">
                {Math.floor(pallets / 42)}
              </dd>
          </div>
        </dl>/*}

        <dl className="space-y-4 py-5">
          {/*<div className="flex items-center justify-between">
            <dt className="">{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</dt>
            <dd className="">{CurrencyHelpers.formatForCurrency(productPrice)}</dd>
          </div>*/}

          {/*cart?.shippingInfo && (
            <div className="flex items-center justify-between">
              <dt className="flex items-center ">
                <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
              </dt>
              <dd className="">{CurrencyHelpers.formatForCurrency(cart?.shippingInfo?.price || {})}</dd>
            </div>
          )*/}

          {/*<div className="flex items-center justify-between border-t border-gray-200 pt-4">
            <dt className="flex">
              <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}</span>
            </dt>
            <dd className="">{CurrencyHelpers.formatForCurrency(discountPrice)}</dd>
          </div>*/}

          <div className="flex items-center justify-between border-t border-gray-200 pt-4">
            <dt className="cart-summary-title">
              {formatCartMessage({ id: 'orderTotal', defaultMessage: 'Order total' })}
            </dt>
            <dd className="cart-summary-title">{CurrencyHelpers.formatForCurrency({...cart?.sum,minimumFractionDigits: 2} || {})}</dd>
          </div>
          {/* {cart?.taxed && (
            <div className="tax-note">
              (
              {formatCartMessage({
                id: 'includedVat',
                defaultMessage: 'Tax included',
                values: { amount: CurrencyHelpers.formatForCurrency(totalTaxes || {}) },
              })}
              )
            </div>
          )} */}
          {!!orderAmountMin && cart?.sum.centAmount < orderAmountMin &&(
            <div className="flex items-center justify-between text-red-600">
              <dt className="flex">{formatCartMessage({ id: 'theMinimumOrderAmountIs', defaultMessage: 'The minimum order amout is ' })}</dt>
              <dd className="">{CurrencyHelpers.formatForCurrency({centAmount: orderAmountMin, currencyCode: cart?.sum?.currencyCode, fractionDigits: cart?.sum?.fractionDigits, minimumFractionDigits: 2} || {})}</dd>
            </div>
          )}
        </dl>

        {isQuoteRequestDisplayed && (
          <div>
            <label className="mb-3" htmlFor="comment">
              <span>{formatCartMessage({ id: 'comment', defaultMessage: 'Comment' })}</span>
              <textarea
                className="input input-primary mt-2"
                id="comment"
                name="comment"
                required
                onChange={(e) => setQuoteComment(e.target.value)}
                value={quoteComment}
              />
            </label>
          </div>
        )}
        {showDiscountsForm && !isQuoteRequestDisabled && cart?.sum.centAmount < orderAmountMin && <DiscountForm cart={cart} className="py-10" />}
        <div className="flex flex-col items-center">
          <>
            {!isQuoteRequestDisplayed && showSubmitButton && (
              <div className="border-color-gray flex w-full flex-col items-center items-center border-t">
                <button type="button" onClick={onSubmit} className="btn-checkout btn-primary mt-5 w-full" disabled={ cart?.sum.centAmount < orderAmountMin }>
                  {!submitLoading &&
                    (submitButtonLabel || formatCartMessage({ id: 'checkout', defaultMessage: 'Checkout' }))}
                  {submitLoading && <LoadingIcon className="mx-auto animate-spin" />}
                </button>
                <ReassignCartButton organization={organization} className="mt-4" />
                {currentStep === 'cart' && !isQuoteRequestDisabled && (
                  <button className={`btn-popover ${isRequestAQuoteDisabled ? "btn-popover mt-4 font-bold" : "btn-popover-disabled mt-4 font-bold"}`} type="button" onClick={handleQuoteRequest} disabled={ cart?.sum.centAmount < orderAmountMin }>
                      {formatCartMessage({ id: 'create-quote-question', defaultMessage: 'Request quote' })}
                  </button>
                )}
                {/*currentStep === 'cart' && isQuoteRequestDisabled && (
                  <button className="button -button-secondary mt-4 text-red-400" type="button" disabled={true}>
                    {formatCartMessage({
                      id: 'quote-error',
                      defaultMessage: 'You cannot request for a quote on a cart created from a quote',
                    })}
                  </button>
                  )*/}

                {submitButtonLabel ===
                  formatCartMessage({ id: 'ContinueAndPay', defaultMessage: 'Place order' }) && (
                  <p className="px-1 py-5 text-center text-xs">
                    <Trans i18nKey="disclaimer" t={t} components={interpolatedComponents} />
                  </p>
                )}
              </div>
            )}
            {isQuoteRequestDisplayed && showSubmitButton && (
              <button disabled={isLoading} className="btn-primary mt-4" type="button" onClick={handleCreateQuote}>
                {!isLoading && <>{formatCartMessage({ id: 'create-quote', defaultMessage: 'Submit quote request' })}</>}
                {isLoading && <LoadingIcon className="mx-auto animate-spin" />}
              </button>
            )}
          </>
        </div>
      </div>
    </section>
  );
};

export default OrderSummary;
